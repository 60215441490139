<template>
  <v-card v-if="perm_project.status !== 'hide'"
          class="mt-3 ml-1">
    <v-toolbar
        style="border-bottom: 2px solid #ececec;"
        color="#fafafa"
        flat
        dense
    >
      <slot name="win-title-help"/>
      <v-toolbar-title class="grey--text text--lighten-2">
        <slot name="win-title-left"/>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <slot name="win-title-right"/>
      <!--
            <v-text-field
                hide-details
                single-line
            ></v-text-field>

            <v-btn icon>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
      -->
      <!--
            <v-btn icon>
              <v-icon class="grey--text text--lighten-2">mdi-dots-vertical</v-icon>
            </v-btn>
      -->
    </v-toolbar>
    <v-container style="min-height: 30vh;" class="innershadow" fluid>
      <slot v-if="perm_project.status === 'on'"/>
      <div v-else> {{ perm_project.message }} </div>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {permission_project: String},
  data: () => ({}),
  computed: {
    perm_project() {
      if (this.permission_project) {
        return this.$store.state.project.permission[this.permission_project]
      } else return {status: 'on'}
    },
    slotPassed() {
      window.console.log("======== slotPassed ===========", this.$slots.default)
      // return !!this.$slots.default[0].text.length
      return true
    }
  }
}
</script>

<style scoped>

.innershadow {
  box-shadow: 0px -4px 12px 0px rgb(224 224 224) inset;
}

</style>